.loader-container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
}

.loader-svg {
   width: 100px;
   height: 100px;
}

@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@300..900&display=swap');

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'Merienda', cursive;
}

ul,
li,
dl {
   list-style-type: none;
   margin: 0;
   padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   margin: 0;
}

p {
   margin: 0;
   padding: 0;
}

.divider {
   border: 1px solid #000;
   width: 240px;
   height: 0px;
   display: block;
   margin: 0 auto;
}

::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   background-color: #f5f5f5;
}

::-webkit-scrollbar {
   width: 12px;
   background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   background-color: #555;
}
